const getValueFromStorage = (name: any) => {
    return localStorage.getItem(name);
  };
  const setValueFromStorage = (name: any, value: any) => {
    return localStorage.setItem(name, value);
  };
  const removeValueFromStorage = (name: any) => {
    return localStorage.removeItem(name);
  };
  const ATSession = {
    getValueFromStorage,
    setValueFromStorage,
    removeValueFromStorage
  };
  export default ATSession;
  