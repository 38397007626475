import Dropdown from 'react-bootstrap/Dropdown';
import Jazzicon, { jsNumberForAddress } from 'react-jazzicon'
import logo from '../../assets/images/logo.png';
import logout from '../../assets/images/logout.png';
import bitcoin from '../../assets/images/bitcoin.png';

function ATCHeader(props: any) {
  return (
    <div className="header-container d-flex justify-content-between">
      <div className="left-head">
        <img src={logo} alt="logo" />
      </div>

      <div className="right-head">
        <button className={props.walletInfo ? "connect_wallet hide" : "connect_wallet"} onClick={() => { props.connectAction(); }}>Connect Wallet</button>
        {props.walletInfo &&
          <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              <div className="left_wallet"><Jazzicon diameter={40} seed={jsNumberForAddress(props.walletInfo?.accounts[0].address)} /></div>
              <div className="add_right_wallet">{props.walletInfo?.accounts[0].address.substring(0, 4)}...{props.walletInfo?.accounts[0].address.substring(props.walletInfo?.accounts[0].address.length - 5)}
                <span><img className="bitcoin_img" src={bitcoin} alt="bg" /> {props.balance} Caddie</span>
              </div>
            </Dropdown.Toggle>
            <Dropdown.Menu>
               <Dropdown.Item className="logout" onClick={props.logOutAction}><img className="logout_img" src={logout} alt="bg" /> Logout</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        }
      </div>
    </div>
  );
}

export default ATCHeader