import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import publicRoutes from './routes/routes';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <Router>
      <Routes>
        {publicRoutes.map((route) => (
          <Route path={route.path} element={<route.component />} />
        ))}
      </Routes>
      <ToastContainer />
    </Router>
  );
}

export default App;
