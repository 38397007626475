import React from 'react';
import HashLoader from 'react-spinners/HashLoader';

function ATCLoader(props: any) {
  return (
    <div className="ground-container-inner">
      <div className="nodata">
        <div className="loader-container">
          <HashLoader color={"#ffffff"} />
        </div>

        <p>{props.title}</p>
      </div>
    </div>
  );
}
export default ATCLoader;
