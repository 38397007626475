import ATClaimVC from '../components/claim/ATClaimVC';

const publicRoutes = [
  {
    path: '/',
    exact: true,
    component: ATClaimVC
  }
];

export default publicRoutes;
