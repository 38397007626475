import axios from 'axios';
import ATCAPI from '../helpers/ATClaimAPI';
import ATCConfig from '../helpers/ATClaimConfig';

export const getTokensList = (params: any) => {
  return new Promise((resolve) => {
    axios
      .get(`${ATCConfig.API_BASE + ATCAPI.METHOD_GET_ADDRESS}?address=${params.address}&type=${params.type}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({
          status: false,
          message: error.message
        });
      });
  });
};

export const getSignature = (params: any) => {
  console.log(ATCConfig);
  return new Promise((resolve) => {
    const header = ATCAPI.API_HEADER;
    axios
      .post(ATCConfig.API_BASE + ATCAPI.METHOD_SIGNATURE, params, {
        headers: header
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({
          status: false,
          message: error.message
        });
      });
  });
};

export const storePendingTransaction = (params: any) => {
  console.log(ATCConfig);
  return new Promise((resolve) => {
    const header = ATCAPI.API_HEADER;
    axios
      .post(ATCConfig.API_BASE + ATCAPI.METHOD_SET_PENDING_HISTORY, params, {
        headers: header
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({
          status: false,
          message: error.message
        });
      });
  });
};


export const getPendingTransaction = (params: any) => {
  return new Promise((resolve) => {
    axios
      .get(`${ATCConfig.API_BASE + ATCAPI.METHOD_GET_PENDING_HISTORY}?address=${params.address}&type=${params.type}`)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({
          status: false,
          message: error.message
        });
      });
  });
};

export const postHistory = (params: any) => {
  console.log(ATCConfig);
  return new Promise((resolve) => {
    const header = ATCAPI.API_HEADER;
    axios
      .post(ATCConfig.API_BASE + ATCAPI.METHOD_HISTORY, params, {
        headers: header
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        resolve({
          status: false,
          message: error.message
        });
      });
  });
};