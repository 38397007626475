import { ethers } from 'ethers';
import claimAbi from '../abi/claimAbi';
import erc20Abi from '../abi/erc20Abi';
import ATCConfig from './ATClaimConfig';

const getContractInstanceWithProvider = async (
  contractAddress: string,
  abi: any,
  rpcUrl: string
) => {
  const web3Provider = new ethers.providers.JsonRpcProvider(rpcUrl);
  const contractObj = new ethers.Contract(contractAddress, abi, web3Provider);
  return contractObj;
};

const getContractInstanceWithSigner = async (contractAddress: string, abi: any, provider: any) => {
  const web3Provider = new ethers.providers.Web3Provider(provider);
  const signer = await web3Provider.getSigner();
  const contractObj = new ethers.Contract(contractAddress, abi, signer);
  return contractObj;
};

const getCurrentNonce = async (provider: any, wallet: string) => {
  const web3Provider = new ethers.providers.Web3Provider(provider);
  const nonce = await web3Provider.getTransactionCount(wallet, 'pending');
  return nonce;
};

const getReceiptForHash = async (hash: string, provider: any) => {
  const web3Provider = new ethers.providers.Web3Provider(provider);
  const tresult = await web3Provider.send('eth_getTransactionReceipt', [hash]);
  return tresult;
};

const getBalance = async (wallet: any) => {
  const contractObj = await getContractInstanceWithProvider(
    ATCConfig.ERC20_CONTRACT,
    erc20Abi,
    ATCConfig.RPC_URL
  );
  let balance = await contractObj.balanceOf(wallet?.accounts[0].address);
  balance = ethers.utils.formatEther(balance.toString());
  return numberFormat(balance);
};

const claimToken = async (wallet: any, amount: any, apiData: any) => {
  try {
    const contractObj = await getContractInstanceWithSigner(
      ATCConfig.CLAIM_CONTRACT,
      claimAbi,
      wallet.provider
    );
    await getCurrentNonce(
      wallet.provider,
      wallet?.accounts[0].address
    );
    const amountValue = ethers.utils.parseEther(amount.toString())._hex;
    const claimResult = await contractObj.claim(
      amountValue,
      apiData.deadline,
      apiData.v,
      apiData.r,
      apiData.s
    );
    return { status: true, hash: claimResult.hash };
  } catch (error: any) {
    return { status: false, message: error.reason };
  }
};

const numberFormat = (value: number) => {
  const internationalNumberFormat = new Intl.NumberFormat('en-US');
  return internationalNumberFormat.format(value);
};

const ATCBlockchainUtils = {
  claimToken,
  getReceiptForHash,
  getBalance
};
export default ATCBlockchainUtils;
