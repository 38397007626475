import Button from 'react-bootstrap/Button';
import { useTranslation } from 'react-i18next';
import { init, useConnectWallet } from '@web3-onboard/react';
import injectedModule from '@web3-onboard/injected-wallets';
import ATSession from '../../helpers/ATSession';
import { getTokensList, getSignature, storePendingTransaction, getPendingTransaction } from '../../services/ATClaimAPIService';
import ATCBlockchainUtils from '../../helpers/ATCBlockchainUtils';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import ATCHeader from '../../UI/header/ATCHeader';
import ATCLoader from '../../UI/loader/ATCLoader';
import { ethers } from 'ethers';
import bg from '../../assets/images/BG-01.svg';
import leafs from '../../assets/images/FG-01.svg';
import golf from '../../assets/images/GolfCart-02.svg';
import machine from '../../assets/images/CaddieCash-Machine-01.svg';
import sloth from '../../assets/images/Sloth-01.svg';
import { useSpring, animated } from 'react-spring';
//inject module
const injected = injectedModule();
init({
  wallets: [injected],
  chains: [
    {
      id: process.env.REACT_APP_CHAIN_ID as string,
      token: 'ETH',
      label: process.env.REACT_APP_CHAIN_NAME as string,
      rpcUrl: process.env.REACT_APP_RPC_URL as string
    }
  ],
  appMetadata: {
    name: process.env.REACT_APP_NAME as string,
    icon: '<svg><svg/>',
    description: process.env.REACT_APP_DESC as string,
    recommendedInjectedWallets: [{ name: 'MetaMask', url: 'https://metamask.io' }]
  },
  accountCenter: {
    desktop: {
      position: 'topRight',
      enabled: false
    },
    mobile: {
      position: 'topRight',
      enabled: false
    }
  }
});

function ATClaimVC() {
  const [{ wallet }, connect, disconnect] = useConnectWallet();
  const [address, setAddress] = useState('');
  const [walletStatus, setWalletStatus] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(false);
  const [totalRewards, setTotalRewards] = useState(0);
  const [walletBalance, setWalletBalance] = useState('');
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [layerStatus, setLayerStatus] = useState(false);
  const { t } = useTranslation();

  const [imgWidth] = useState<number>(1900);
  const [imgHeight] = useState<number>(1200);
  const [width] = useState<number>(window.innerWidth);
  const [height] = useState<number>(window.innerHeight);
  const [carStyle, setCarStyles] = useSpring(() => ({ left: "0%", top: "0%" }))
  const [atmStyle, setATMStyles] = useSpring(() => ({ left: "0%" }))
  const [slothStyle, setSlothStyles] = useSpring(() => ({ opacity: 1 }))
  const [layerStyle, setLayerStyles] = useSpring(() => ({ left: 0 + 'px', top: 0 + 'px', width: window.innerWidth + 'px', height: window.innerHeight + 'px' }))
  const [atmStatus, setATMStatus] = useState(false)
  const [panelStyle, setPanelStyle] = useState({ width: width + 'px', height: (width / imgWidth) * imgHeight + 'px', left: 0 + 'px', top: (height - ((width / imgWidth) * imgHeight)) / 2 + 'px' });
  const [isClaimEnabled, setIsClaimEnabled] = useState({ display: "none"})
  const [isBoardnabled, setIsBoardEnabled] = useState({ display: "none"})

  useEffect(() => {
    setLayerStatus(false);
  }, []);
  useEffect(() => {
    setCarStyles.start({ left: atmStatus ? "0%" : "0%", top: atmStatus ? "0%" : "0%" })
    setTimeout(() => {
      setATMStyles.start({ left: atmStatus ? "0%" : "0%" })
      setSlothStyles.start({ opacity: atmStatus ? 1 : 1 })
      setTimeout(() => {
        let newWidth = atmStatus ? window.innerWidth * 3.0 : window.innerWidth
        let newHeight = atmStatus ? window.innerHeight * 3.0 : window.innerHeight
        setZoomValue(newWidth, newHeight)
        setBoardSize(newWidth, newHeight)
        setTimeout(() => {
          setLayerStatus(true);
        }, 1000);
      }, 200);
    }, 100);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atmStatus, setATMStyles, setSlothStyles, setCarStyles, setLayerStyles])

  const setZoomValue = (newWidth: number, newHeight: number) => {
    let boardWidth = 0;
    let boardHeight = 0;
    let boardLeft = 0;
    let boardTop = 0;
    if (newWidth > newHeight) {
      boardWidth = (newHeight / imgHeight) * imgWidth
      if (boardWidth < newWidth) {
        boardWidth = newWidth
      }
      boardHeight = (boardWidth / imgWidth) * imgHeight;
    } else {
      boardHeight = (newWidth / imgWidth) * imgHeight;
      if (boardHeight < newHeight) {
        boardHeight = newHeight
      }
      boardWidth = (boardHeight / imgHeight) * imgWidth;
    }

    boardLeft = (window.innerWidth - boardWidth) / 2
    boardTop = (window.innerHeight - boardHeight) / 2
    let newLeft = 0;
    if (newWidth > newHeight) {
      newLeft = atmStatus ? boardLeft + -(boardWidth * 0.13) : boardLeft;
    } else {
      newLeft = atmStatus ? boardLeft + -(boardWidth * 0.13) : boardLeft + (boardWidth * 0.17);
    }

    let newTop = atmStatus ? boardTop : boardTop;

    setLayerStyles.start({ left: newLeft + 'px', top: newTop + 'px', width: boardWidth + 'px', height: boardHeight + 'px' })
  }

  const setBoardSize = (newWidth: number, newHeight: number) => {
    setTimeout(() => {
      let boardWidth = 0;
      let boardHeight = 0;
      let boardLeft = 0;
      let boardTop = 0;
      if (newWidth > newHeight) {
        boardWidth = (newHeight / imgHeight) * imgWidth
        if (boardWidth < newWidth) {
          boardWidth = newWidth
        }
        boardHeight = (boardWidth / imgWidth) * imgHeight;
      } else {
        boardHeight = (newWidth / imgWidth) * imgHeight;
        if (boardHeight < newHeight) {
          boardHeight = newHeight
        }
        boardWidth = (boardHeight / imgHeight) * imgWidth;
      }
      boardLeft = 0
      boardTop = 0

      setPanelStyle({ width: boardWidth + 'px', height: boardHeight + 'px', top: boardTop + 'px', left: boardLeft + 'px' })

      setIsClaimEnabled({display:atmStatus ?  "block" : "none"})
      setIsBoardEnabled({display:"block"})

    }, 1000);
  }

  useEffect(() => {
    if (!wallet) {
      ATSession.removeValueFromStorage('wallet');
      ATSession.removeValueFromStorage('address');
    } else {
      if(wallet.chains[0].id !== "0x1") {
        if (wallet) {
          disconnect(wallet)
        }
        ATSession.removeValueFromStorage('wallet');
        ATSession.removeValueFromStorage('address');
        toast('Alpha Traderd Country Club supports only Ethereum Mainnet. Please switch your network');
        return
      }
      ATSession.setValueFromStorage('wallet', wallet.label);
      ATSession.setValueFromStorage('address', wallet.accounts[0].address);

      getUserClaimData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wallet]);

  const connectAction = () => {
    connect();
  }

  const handleChange = (event: any) => {
    if (event.target.value.length === 0) {
      setWalletStatus(false);
    }
    setAddress(event.target.value);
  };


  const checkAddress = () => {
    if (ethers.utils.isAddress(address)) {
      setWalletStatus(true);
      ATSession.setValueFromStorage('wallet', address);
      ATSession.setValueFromStorage('address', address);
      getUserClaimData();
    } else {
      toast('Invalid addres');
    }
  }

  const getUserClaimData = async () => {
    try {
      let balanceResult = await ATCBlockchainUtils.getBalance(wallet);
      setWalletBalance(balanceResult);
    } catch (error) {
      setWalletBalance('0');
    }
    getTokensList({
      address: wallet?.accounts[0].address,
      type: 0
    }).then(async (resultMain: any) => {
      console.log(resultMain);
      setTotalRewards(resultMain.rewards);
      if (resultMain.claim_status === 1) {
        checkPendingTransaction();
      }

    });
  }

  const checkPendingTransaction = () => {
    var params = {
      address: wallet?.accounts[0].address
    }
    getPendingTransaction(params).then(async (resultMain: any) => {
    });
  }

  const claimAction = () => {
    setLoaderStatus(true);
    var params = {
      address: wallet?.accounts[0].address
    }
    getSignature(params).then(async (resultMain: any) => {
      if (resultMain.status) {
        const claimResult = await ATCBlockchainUtils.claimToken(wallet, totalRewards, resultMain.data);
        if (claimResult.status === true) {
          storePendingTransaction({
            address: wallet,
            hash: claimResult.hash
          }).then(async (resultMain: any) => {
          });
          getTransactionToken(claimResult.hash, 'claim', 0);
        } else {
          setLoaderStatus(false);
          toast(claimResult.message);
        }
        return;
      } else {
        setLoaderStatus(false);
        toast(resultMain.message);
      }
    });
  }

  /**
 * This is the function which used to track for token id
 */
  const getTransactionToken = async (hash: string, type: string, detail: any) => {
    const receipt = await ATCBlockchainUtils.getReceiptForHash(hash, wallet?.provider);
    setTimeout(() => {
      parseTokenResult(receipt, hash, type, detail);
    }, 3000);
  };

  /**
  * This is the function which used to track for token id on loop
  */
  const parseTokenResult = (result: any, transactionHash: string, type: string, detail: any) => {
    if (result != null) {
      setTimeout(() => {
        if (type === 'claim') {
          console.log('hash', transactionHash);
          setTotalRewards(0);
          setLoaderStatus(false);
          setTransactionStatus(true);
          toast(t('Claim has been completed successfully!'));
        }
        getUserClaimData();
      }, 5000);
      return;
    }
    getTransactionToken(transactionHash, type, detail);
  };

  const logOutAction = () => {
    if (wallet) {
      disconnect(wallet)
    }
    ATSession.removeValueFromStorage('wallet');
    ATSession.removeValueFromStorage('address');
    window.location.reload();
  }

  const showATM = () => {
    if (wallet) {
      setATMStatus(atmStatus ? false : true)
    } else {
      toast("Connect wallet and try to claim!");
    }
  }

  return (
    <div className='mainContainer'>
      <ATCHeader walletStatus={walletStatus} handleChange={handleChange} address={address} checkAddress={checkAddress} walletInfo={wallet} logOutAction={logOutAction} balance={walletBalance} connectAction={connectAction} />
      {layerStatus &&
        <animated.div className='layerContainer' style={layerStyle}>
          <div className='positionContainer'>
            <img src={bg} alt="bg" />
          </div>
          <animated.div className='positionContainer' style={carStyle}>
            <img src={golf} alt="bg" />
            
          </animated.div>
          <div className='positionContainer'>
            <img src={leafs} alt="bg" />
          </div>
          <animated.div className='positionContainer' style={atmStyle}>
            <img src={machine} alt="bg" />
            <div className='atm-overlay' style={panelStyle}>
            <div className='board-layer' style={isBoardnabled}>
            <div className="white_board_box_one">
              <div className="getting_start">
                <h3>Click To Get Started</h3>
                <button onClick={showATM}>Begin</button>
              </div>
            </div>
              </div>
              <div className='screen-layer' style={isClaimEnabled}>
                <div className={!transactionStatus && !loaderStatus ? "earned_cash" : "earned_cash hide"}>
                  <h5>You've Earned</h5>
                  <h3>{totalRewards}</h3>
                  <p>$caddie</p>
                </div>
                {loaderStatus &&
                  <div className="claimloader">
                    <ATCLoader title={t('Please Wait...')} />
                  </div>
                }
                <div className={transactionStatus ? "claimed active" : "claimed hide"}>
                  <div className='center_claimed'>
                   <h3>Claimed</h3>
                  </div> 
                </div>
              </div>
              <div className='pad-layer' style={isClaimEnabled}>
                <div className="claim">
                  {!loaderStatus && totalRewards > 0 &&
                    <Button
                      onClick={() => {
                        claimAction();
                      }}>
                      {t('Claim')}
                    </Button>
                  }
                </div>
              </div>
            </div>
          </animated.div>
          <animated.div className='positionContainer' style={slothStyle}>
            <img src={sloth} alt="bg" />
          </animated.div>
        </animated.div>
      }
    </div>
  )
}

export default ATClaimVC;
